import { useContext } from "react";
import { BsFacebook, BsInstagram, BsYoutube } from "react-icons/bs";
import Typewriter from "typewriter-effect";
import { InitializeContext } from "../../App";
import banner from "../../assets/me/me.jpg";

const Fade = require("react-reveal/Fade");

export default function Banner() {
  const { theme } = useContext(InitializeContext);
  return (
    <section
      id={"home"}
      className={`md:py-16 pb-5 md:pb-0 border-b ${
        theme
          ? "text-white bg-[#212428] border-[#212428]"
          : "bg-[#ECF0F3] text-black border-[#ECF0F3]"
      }`}
    >
      <div className="hero">
        <div className="hero-content flex-col justify-between lg:flex-row-reverse">
          <Fade bottom distance="20px">
            <div className="w-full md:w-2/3 lg:w-1/3 rounded-xl shadow-xl overflow-hidden lg:ml-6">
              <div className="outline-none p-1 md:p-3">
                <img
                  src={banner}
                  className="rounded-xl w-full"
                  draggable={false}
                  alt="Tb Rasel"
                />
              </div>
            </div>
          </Fade>
          <Fade bottom distance="30px">
            <div className="lg:w-1/2 pt-5 lg:pt-0 leading-loose">
              <h1 className="text-3xl md:text-5xl lg:text-5xl font-bold leading-tight">
                Hi, I’m{" "}
                <span
                  className={`${
                    theme ? "text-warning" : "text-primary"
                  } text-4xl md:text-5xl lg:text-6xl font-stabillo`}
                  title="Tb Rasel"
                >
                  Tb Rasel
                </span>
              </h1>
              <h2 className="flex items-center gap-2 text-xl md:text-2xl lg:text-3xl font-semibold leading-tight mt-3">
                <span className={`${theme ? "text-warning" : "text-primary"}`}>
                  a
                </span>
                <Typewriter
                  options={{
                    strings: ["Musician", "Digital Marketer", "Youtuber"],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </h2>
              <p className="py-4 mt-5">
                I'm the founder and CEO of '
                <a
                  href="https://mirrorltd.com"
                  target="_blank"
                  rel="noreferrer"
                  className={`font-semibold ${
                    theme ? "text-warning" : "text-primary"
                  }`}
                >
                  Mirror-LTD Music
                </a>
                '. I started the company in 2021. I'm also a Musician, Youtuber
                and Digital Marketer.
              </p>
              <p className="text-start text-sm my-5">Find With Me</p>
              <div className="flex justify-start items-center gap-4">
                <a
                  href="https://www.facebook.com/ImdRasel35"
                  target="_blank"
                  rel="noreferrer"
                  className={`border cursor-pointer py-4 px-5 shadow-xl rounded-xl ${
                    theme
                      ? "hover:bg-warning hover:border-warning"
                      : "hover:bg-primary hover:border-primary glass"
                  } hover:text-white duration-500`}
                >
                  <BsFacebook size={20} />
                </a>
                <a
                  href="https://www.instagram.com/tb_rasel"
                  target="_blank"
                  rel="noreferrer"
                  className={`border cursor-pointer py-4 px-5 shadow-xl rounded-xl ${
                    theme
                      ? "hover:bg-warning hover:border-warning"
                      : "hover:bg-primary hover:border-primary glass"
                  } hover:text-white duration-500`}
                >
                  <BsInstagram size={20} />
                </a>
                <a
                  href="https://www.youtube.com/@tbraselbd"
                  target="_blank"
                  rel="noreferrer"
                  className={`border cursor-pointer py-4 px-5 shadow-xl rounded-xl ${
                    theme
                      ? "hover:bg-warning hover:border-warning"
                      : "hover:bg-primary hover:border-primary glass"
                  } hover:text-white duration-500`}
                >
                  <BsYoutube size={20} />
                </a>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
}
